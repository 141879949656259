import { Link } from "react-router-dom";

import { IQuestion } from '../../schemas/QuestionSchemas';
import UnresolvedQuestionOptions from './UnresolvedQuestionOptions';
import OpenQuestionOptions from './OpenQuestionOptions';
import ResolvedQuestionOptions from './ResolvedQuestionOptions'; 
import { DisplayText } from "../Common/Text";
import ForecastChart from "./ForecastChart";

function getPageUrl(id: string) {
  return `/question/${id}`
}

function getForecastUrl(id: string) {
  return `/forecast/${id}`
}

interface ISubtitleProps {
  question: IQuestion;
}

function QuestionFooter(props: ISubtitleProps) {
  const question = props.question;
  const resolveDate = question.resolutionDate ? new Date(question.resolutionDate) : null;
  return (
    <div>
      {
        resolveDate &&
        <div className="question-subtitle">
          ✅ {resolveDate.toDateString()}
        </div>
      }
  </div>);
}

function packageData(data: IQuestion) {
  const labels = data.possibilities;

  // Create an array of length labels.length, filled with empty arrays
  const predictionsData = Array.from({length: labels.length}, () => Array<number>());

  data?.forecasts?.edges.forEach((edge) => {
    const forecast = edge.node;
    const predictions = forecast.responses;
    predictions.forEach((prediction, index) => {
      predictionsData[index].push(prediction);
    })
  });

  const medianValuePerLabel = predictionsData.map((predictions) => {
    const sortedPredictions = predictions.sort();
    const medianIndex = Math.floor(sortedPredictions.length / 2);
    return sortedPredictions[medianIndex];
  });

  const meanValuePerLabel = predictionsData.map((predictions) => {
    const sum = predictions.reduce((a, b) => a + b, 0);
    const mean = sum / predictions.length;
    return mean;
  });

  // To add an additional metric in the chart, add the new label under dataCategories, and compute the value for each label in a new array, like above.
  return {
    dataCategories: ["Mean"],
    labels: labels,
    data: [meanValuePerLabel]
  };
}

interface IQuestionProps {
  question: IQuestion;
  showQuestionLink: boolean;
  showResolvePrompt: boolean;
  detailed: boolean;
  minimal: boolean;
}

const Question = (props: IQuestionProps) => {
  const data = props.question;

  packageData(data);

  let now = new Date();
  let closeDate = new Date(data.closeDate);
  let needsResolution = !data.resolutionDate;

  let optionsElement: JSX.Element;

  if (closeDate < now && needsResolution && props.showResolvePrompt) 
  {
    optionsElement = <UnresolvedQuestionOptions question={data} />;
  } else if (closeDate > now || needsResolution)
  {
    optionsElement = <OpenQuestionOptions question={data} />;
  } else
  {
    optionsElement = <ResolvedQuestionOptions question={data} />;
  }

  let userProfileLink = `/user/${data.creator.username}`;
  let datePrefix = closeDate < now ? "🔒" : "⏳" ;
  let groupPageLink = `/pod/${data.group.id}`;

  return (
    <div className="Question-tile">
      {props.showQuestionLink 
        ? <Link className="question-tile go-to-question" to={getPageUrl(data.id)}>{data.title}</Link>
        : <div className="question-title">{data.title}</div>
      }
      <div className="question-header">
        {data.topics.edges.map((topic) => (
          <p className="question-topic" key={topic.node.id} >{topic.node.name}</p>
        ))}
      </div>
      {
        (props.question.forecasts?.edges.length > 0 && !props.minimal) &&
          <ForecastChart {...(packageData(props.question))} />
      }
      <div className="question-subtitle">
        <b>
          <a href={userProfileLink} className="creator-link">
            {data.creator.username}
          </a>
          </b> ⦙ 
          <a href={groupPageLink} className="creator-link">
            {" " + data.group.name
          }</a> ⦙ 
          {" " + datePrefix + " " + new Date(data.closeDate).toDateString()}
      </div>
      {
        props.detailed &&
          <div className="question-description">
            <DisplayText className="question-description" content={data.description} />
          </div>
      }
      {
        props.detailed &&
          optionsElement
      }
      {closeDate > now &&
        <Link className="question-tile go-to-forecast" to={getForecastUrl(data.id)}>Forecast</Link>
      }
      <QuestionFooter question={data} />
    </div>
  );
};

export default Question;
