import { ApolloCache, FetchResult, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select';

import { IAddQuestionResult, IAllQuestionResult, IQuestionEdge } from '../../schemas/QuestionSchemas';
import { getDaysFromToday } from '../../utils/DateTime';
import { ADD_QUESTION } from '../../mutations/Question'; 
import { ALL_QUESTIONS_BY_USER_PRIVILEGE } from '../../queries/Questions';
import TopicPicker from '../Topic/TopicPicker';
import GroupPicker from '../Groups/GroupPicker';

import { InputText } from '../Common/Text';
 
interface OutcomeOption {
    possibility: string;
}

function CreateQuestion() {
    const formClassName = "createQuestionForm";

    let possibilities: OutcomeOption[] = [ { possibility: "" }, { possibility: "" } ];

    const [ options, setOptions ] = useState(possibilities);
    const [ title, setTitle ] = useState<string>("");
    const [ description, setDescription ] = useState<string>("");
    const [ closeDate, setCloseDate ] = useState<string>(getDaysFromToday(1));
    const [ topics, setTopics ] = useState<ValueType<OptionTypeBase, boolean> | undefined>(undefined);
    const [ group, setGroup ] = useState<any | undefined>(undefined);
    
    const history = useHistory();

    const updateCache = (cache: ApolloCache<IAddQuestionResult>, 
        { data }: FetchResult<IAddQuestionResult, Record<string, any>, Record<string, any>>) => 
            {
                // Fetch the questions from the cache
                const existingQuestions = cache.readQuery<IAllQuestionResult, any>({
                    query: ALL_QUESTIONS_BY_USER_PRIVILEGE
                });

                if (existingQuestions?.allQuestionsByUserPrivilege && data) {

                    let newQuestion: IQuestionEdge = {
                        node: data.addQuestion.question
                    };

                    cache.writeQuery({
                        query: ALL_QUESTIONS_BY_USER_PRIVILEGE,
                        data: {allQuestionsByUserPrivilege: { edges: [newQuestion, ...existingQuestions.allQuestionsByUserPrivilege]}}
                    });
                }
            };

    const [ addQuestion ] = useMutation<IAddQuestionResult, any>(ADD_QUESTION,   {
        onCompleted({ addQuestion }) {
            // Navigate to the newly created question.
            history.push(`/question/${addQuestion.question.id}`);
        },
        update: updateCache
    });

    const removePossibility = (index: number) => {
        setOptions((options) => [...options.filter((_, idx) => idx !== index) ]);
    }

    const handleOptionChange = (newValue: string, index: number) => {
        const newOptions = options.map((option, idx) => {
            if (idx !== index) return option;
            return { ...option, possibility: newValue };
        });

        setOptions(newOptions);
    }

    const handleStringChange = (newValue: string, lambda: React.Dispatch<React.SetStateAction<string>>) => {
        lambda(newValue.trimStart());
    }

    const handleTopicChange = (value: ValueType<OptionTypeBase, boolean>, actionMeta: ActionMeta<OptionTypeBase>) => {
        setTopics(value);
    }

    const handleGroupChange = (value: any, actionMeta: ActionMeta<OptionTypeBase>) => {
        setGroup(value);
    }

    const handleSubmit = () => {
        if (!title || !closeDate || !group) {
            // TODO Replace this with a more graceful error screen eventually.
            alert("Please fill out all fields");
            return;
        }

        if (options.length < 2) {
            alert("Please provide at least two options");
            return;
        }

        addQuestion({ variables: {
            title: title.trim(),
            description: description.trim(),
            closeDate: closeDate.trim(),
            possibilities: options?.map(option => option.possibility.trim()),
            groupId: group.value,
            topics: topics?.map((topic: { label: string, value: string }) => topic.value) ?? [] } });
    }

    return (
    <div className={formClassName + '-wrapper'}>
        <h1 className='form-title'>Create a New Question</h1>
        <div className={formClassName}>
            <p className={formClassName}>Questions are the central locus of forecasting. Here, we define what questions to pose to other users on Rorqual. To preserve integrity of the forecasts, questions are entirely immutable.</p>
        </div>
        <form className={formClassName}
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}>
            <div className={formClassName}>
                <label className={formClassName}>Question</label>
            </div>
            <div className={formClassName}>
                <p className={formClassName}>This is the main question you're presenting for forecasting. Forecasters should be able to understand what you're asking just by reading this question alone.</p>
            </div>
            <div className={formClassName}>
                <input
                    className={formClassName}
                    type = "text"
                    required = {true}
                    value = {title}
                    onChange = {e => handleStringChange(e.target.value, setTitle)}
                />
            </div>
            <div className={formClassName}>
                <label className={formClassName}>Description - <a href="https://www.markdownguide.org/cheat-sheet/">Markdown is supported</a></label>
            </div>
            <div className={formClassName}>
                <p className={formClassName}>Provide additional context for your question. Help people understand the history, industry, technology, or whatever else is relevant. Include links to external resources as well.</p>
            </div>
            <InputText className={formClassName} handleChange={setDescription} content={description} />
            <div className={formClassName}>
                <label className={formClassName}>Topic</label>
            </div>
            <div className={formClassName}>
                <p className={formClassName}>Select the topics which best reflect the category of your question.</p>
            </div>
            <div className={formClassName}>
                <TopicPicker topicChangeHandler={handleTopicChange} numSelected={topics?.length} />
            </div>
            <div className={formClassName}>
                <label className={formClassName}>Pod</label>
            </div>
            <div className={formClassName}>
                <p className={formClassName}>Pods are selecting your audience for the question.</p>
            </div>
            <div className={formClassName}>
                <GroupPicker groupChangeHandler={handleGroupChange} numSelected={group?.length} />
            </div>
            <div className={formClassName}>
                <label className={formClassName}>Close Date</label>
            </div>
            <div className={formClassName}>
                <input
                    className={formClassName}
                    type = "date"
                    required = {true}
                    min = {getDaysFromToday(1)}
                    value = {closeDate}
                    onChange = {e => handleStringChange(e.target.value, setCloseDate)}
                />
            </div>
            <div className={formClassName}>
                <label className={formClassName}>Options</label>
            </div>
            <div className={formClassName}>
                <p className={formClassName}>You need a minimum of two options to create a question. These should be unambiguous and discrete, so that it's clear what the forecaster is predicting.</p>
            </div>
            <div className={formClassName}>
                <div className={"input-possibilities"}>
                    {
                        options.map((obj, index) => 
                            <div className={"possibility"} key={"possibility-div"+index}>
                                <div className='possibility' key={'possibility-input'+index}>
                                    <input
                                        key = {"possibility-input"+index}
                                        className={formClassName + " possibility"}
                                        type = "text"
                                        value = {obj.possibility.trimStart()}
                                        required = {true}
                                        onChange = {e => handleOptionChange(e.target.value, index)}
                                    />
                                </div>
                                <div className='possibility' key={'possibility-subtract'+index}>
                                    <button 
                                        key={"possibility-button"+index}
                                        className={formClassName + " possibility removeExistingPossibility"}
                                        type="button"
                                        onClick={(_) => removePossibility(index)} >
                                        -
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
                <button type="button"
                    className={formClassName + " addNewPossibility"}
                    onClick={(_) => setOptions(options => [...options, { possibility: "" }])}>
                    +
                </button>
            </div>
            <div className={formClassName}>
                <button className={formClassName + " submit"} type="submit">Launch Question</button>
            </div>
        </form>
    </div>
    );
}

export default CreateQuestion;
