import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { ErrorTypes, ErrorPage, GraphQLErrorCodes } from '../Common/Error';
import { SuccessTypes, SuccessPage } from '../Common/Success';
import { INITIATE_PASSWORD_RESET } from '../../mutations/User';
import { IUserResult } from '../../schemas/UserSchema';

function ForgotPassword() {
  const [email, setEmail] = useState<string>("");

  const [ clientError, setError ] = useState(ErrorTypes.None);
  const handleFieldChange = (newValue: string, changeHandler: React.Dispatch<React.SetStateAction<string>>) => { changeHandler(newValue.trim()); }
  const [ initiatePasswordReset ] = useMutation<IUserResult, any>(INITIATE_PASSWORD_RESET, {
        onCompleted(_) {
            setSuccess(SuccessTypes.PasswordResetInitiated);
            setError(ErrorTypes.None);
        },
        onError: (err) => {
            if (err.graphQLErrors[0].extensions!["code"] === GraphQLErrorCodes.UserWithEmailDoesNotExist) {
                setError(ErrorTypes.UserDoesNotExist);
                setSuccess(SuccessTypes.None);
            }

            if (err.graphQLErrors[0].extensions!["code"] === GraphQLErrorCodes.UserNotVerified) {
                setError(ErrorTypes.VerificationRequired);
                setSuccess(SuccessTypes.None);
            }
        }
    });

  const [ success, setSuccess ] = useState(SuccessTypes.None);

  const onSubmitClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    initiatePasswordReset({
        variables: 
        {
            email: email
        }
    });
  }

  return (
    <div>
      <ErrorPage message={clientError} />
      <SuccessPage message={success} />
      <form className="loginForm"
            onSubmit={onSubmitClick}>
        <label className="loginForm">Email Address</label>
        <input
          className="loginForm"
          placeholder="resetpasswordemail@domain.com"
          type="text"
          required={true}
          value={email}
          onChange={e => handleFieldChange(e.target.value, setEmail)}
        />
        <button className="loginForm" type="submit">Send Password Reset Email</button>
      </form>
    </div >
  );
}

export default ForgotPassword;
