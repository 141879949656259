import { gql } from '@apollo/client';

export const FIND_QUESTION = 
    gql `query findQuestion($id: ID!) { 
            findQuestion(id: $id) {
                    id
                    title
                    description
                    creator {
                      username
                    }
                    possibilities
                    openDate
                    closeDate
                    resolutionDate
                    outcome
                    forecasts {
                        edges {
                            node {
                                id
                                responses
                                forecasterId
                                explanation
                                score
                                forecaster {
                                    username
                                }
                                creationDatetime
                            }
                        }
                    }
                    topics {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    group {
                        id
                        name
                    }
                }
            }`;

export const ALL_QUESTIONS_BY_USER_PRIVILEGE = 
    gql `query allQuestionsByUserPrivilege { 
        allQuestionsByUserPrivilege {
            id
            title
            description
            creator {
                username
            }
            possibilities
            openDate
            closeDate
            resolutionDate
            outcome
            topics {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            group {
                id
                name
                users {
                    edges {
                        node {
                            user {
                                username
                            }
                            associationType
                        }
                    }
                }
            }
            forecasts {
                edges {
                    node {
                        responses
                        creationDatetime
                    }
                }
            }
        }
    }`;
