import { Link } from "react-router-dom";

export default function Footer() {
    return (
    <footer className="footer">
        <nav className="footer-navigation">
            <ul className="footer-menu" id="nav-menu">
                <li>
                    <Link className="footer-menu" to="/about">About</Link>
                </li>
                <li>
                    <Link className="footer-menu" to="/about">Forecasting</Link>
                </li>
            </ul>
        </nav>
    </footer>);
}