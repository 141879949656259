import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getUsername } from "../../auth";
import { REMOVE_USER_FROM_GROUP } from "../../mutations/Group";
import { GET_GROUP } from "../../queries/Group";
import { IGroupNode, GroupAssociationType, IRemoveUserFromGroupResult } from "../../schemas/GroupSchema";
import { ErrorPage, ErrorTypes, GraphQLErrorCodes } from "../Common/Error";
import LoadingPage from "../Common/Loading";
import { QuestionSummary } from "../User/ProfileComponents";

interface GroupParams {
    id: string
}

export function GroupPage() {
    const { id } = useParams<GroupParams>();
    const { loading, error, data } = useQuery<IGroupNode>(GET_GROUP, {
        variables: { id },
    });
    const [ removalError, setRemovalError ] = useState<ErrorTypes | null>(null);
    
    const history = useHistory();

    const [ removeUserFromGroup ] = useMutation<IRemoveUserFromGroupResult, any>(REMOVE_USER_FROM_GROUP,   {
        onCompleted({ removeUserFromGroup }) {
            // Navigate to the newly created group.
            history.push(`/pod/${removeUserFromGroup.group.id}`);
        },
        onError(err) {
            if (err.graphQLErrors[0].extensions!["code"] === GraphQLErrorCodes.GroupAlreadyExists) {
                setRemovalError(ErrorTypes.GroupAlreadyExists);
            }
        }
    });

    if (loading) return <LoadingPage />;

    if (error) return <ErrorPage message={ErrorTypes.Unknown} />

    function getGroupMembersByType(type: GroupAssociationType) {
        if (data) {
            return data?.group.users.edges.filter((u) => u.node.associationType === type).map((u) => u.node.user.username);
        }
        return [];
    }

    function handleExitGroup() {
        removeUserFromGroup({
            variables: {
                groupId: id,
                targetUserId: null
            }
        });
    }

    let members = getGroupMembersByType(GroupAssociationType.MEMBER);
    let admins = getGroupMembersByType(GroupAssociationType.ADMIN);

    let isUserMember = members.includes(getUsername());

    // If the group is private, check if the user is a member.
    if (data?.group.isPrivate) {
        if (!isUserMember && !(admins.includes(getUsername())) && !(data?.group.owner.username === getUsername())) {
            return <ErrorPage message={ErrorTypes.UnAuthorized} />
        }
    }

    return (
        <div className="group-page wrapper" >
            <h1 className="group-page section-title">{data?.group.name}</h1>
            <div className="group-page description">{data?.group.description}</div>
            {
                isUserMember &&
                <div className="group-page exit-group">
                    <button 
                        onClick={handleExitGroup}
                        className="group-page exit-group">
                            Exit Group
                    </button>
                </div>
            }
            {
                removalError &&
                <ErrorPage message={removalError} />
            }
            <div className="profile-summary group-page-summary">
                <div className="profile-summary-element group-page">Owner</div>
                <div className="profile-summary-element group-page"><a className="profile-summary-element" href={`/user/${data?.group.owner.username}`} >{data?.group.owner.username}</a></div>
                <div className="profile-summary-element group-page">Members</div>
                <div className="profile-summary-element group-page">{members.length}</div>
                <div className="profile-summary-element group-page">Admins</div>
                <div className="profile-summary-element group-page">{admins.map((a) => {
                    return <a className="profile-summary-element" href={`/user/${a}`} >{a} </a>
                })}</div>
                <div className="profile-summary-element group-page">Private</div>
                <div className="profile-summary-element group-page">{data?.group.isPrivate ? "Yes" : "No"}</div>
            </div>
            <QuestionSummary userData={data!.group} owner={false} />
        </div>
    );
}