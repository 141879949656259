import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ADD_USER } from '../../mutations/User';
import { ErrorTypes, ErrorPage, GraphQLErrorCodes } from '../Common/Error';
import { SuccessTypes, SuccessPage } from '../Common/Success';
import LoadingPage from '../Common/Loading';

function SignUpPage() {
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [addUser, { loading: addUserLoading }] = useMutation(ADD_USER,   {
        onCompleted({ data }) {
            // Navigate user to the login page.
            history.push("/login?registered=true");
        },
        onError(error) {
            setSuccess(SuccessTypes.None);
            let err = error.graphQLErrors[0];

            switch (err.extensions?.code) {
                case GraphQLErrorCodes.UserWithEmailAlreadyExists:
                    setError(ErrorTypes.UserEmailAlreadyRegistered);
                    break;
                case GraphQLErrorCodes.UserWithUsernameAlreadyExists:
                    setError(ErrorTypes.UserNameAlreadyRegistered);
                    break;
                default:
                    setError(ErrorTypes.Unknown);
            }
        }
      });

    const [error, setError] = useState<ErrorTypes>(ErrorTypes.None);
    const [success, setSuccess] = useState<SuccessTypes>(SuccessTypes.None);
    const history = useHistory();
    const handleFieldChange = (newValue: string, changeHandler: React.Dispatch<React.SetStateAction<string>>) => { changeHandler(newValue.trim()); }

    const onSubmitClick = (e: any) => {
        e.preventDefault();
        addUser({ variables: { username: username, password: password, email: email } });
    }

    return (
        <div>
            { addUserLoading && 
                <LoadingPage />
            }
            <ErrorPage message={error} />
            <SuccessPage message={success} />
            <form className="signUpForm"
                onSubmit={onSubmitClick}>
                <label className="signUpForm">Username</label>
                <input
                    className="signUpForm"
                    placeholder="superforecaster"
                    type="text"
                    required={true}
                    value={username}
                    onChange={e => handleFieldChange(e.target.value, setUsername)}
                />
                <label className="signUpForm">Email address</label>
                <input
                    className="signUpForm"
                    placeholder="best_predictor@accuracy.com"
                    type="email"
                    required={true}
                    value={email}
                    onChange={e => handleFieldChange(e.target.value, setEmail)}
                />
                <label className="signUpForm">Password</label>
                <input
                    className="signUpForm"
                    placeholder="cuddlybunnies123"
                    required={true}
                    type="password"
                    value={password}
                    onChange={e => handleFieldChange(e.target.value, setPassword)}
                />
                <button className="signUpForm" type="submit">Sign Up</button>
            </form>
        </div>
    );
}

export default SignUpPage;
