import { useQuery } from '@apollo/client';
import { IAllTopicResult } from '../../schemas/TopicSchema';
import { ALL_TOPICS } from '../../queries/Topic';
import Select from 'react-select';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select';

interface ITopicPickerProps {
    topicChangeHandler: (value: ValueType<OptionTypeBase, boolean>, actionMeta: ActionMeta<OptionTypeBase>) => void;
    numSelected: number;
}

function TopicPicker(props: ITopicPickerProps) {
    const { data } = useQuery<IAllTopicResult>(ALL_TOPICS);
    const maxTopics = 5;

    let topicsData = data?.allTopics.edges.map(
        (topic) => ({
            label: topic.node.name,
            value: topic.node.id
        })
    )

    return (
        <Select
            closeMenuOnSelect={false}
            isMulti={true}
            options={props.numSelected === maxTopics ? [] : topicsData}
            onChange={props.topicChangeHandler}
            noOptionsMessage={() => {
              return props.numSelected === maxTopics ? `Maximum ${maxTopics} topics allowed` : 'No options available' ;
            }}
        />
    );
}

export default TopicPicker;
