import { useQuery } from '@apollo/client';

import { IFindUserResult } from '../../schemas/UserSchema';
import { getUserId } from "../../auth";
import { GET_USER_PROFILE_DATA } from '../../queries/User';
import LoadingPage from '../Common/Loading';
import { ErrorTypes, ErrorPage } from '../Common/Error';
import { SummaryBox, QuestionSummary, ForecastSummary, MembershipSummary } from './ProfileComponents';
import { logout } from '../../auth';
import { useHistory } from 'react-router-dom';

function Profile() {
    const userId = parseInt(getUserId());
    const history = useHistory();

    const { data, loading } = useQuery<IFindUserResult>(GET_USER_PROFILE_DATA, {
        variables: { id: userId },
        skip: !userId
    });

    const logoutAndRedirect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        logout();
        history.push("/");
    }
    
    if (loading) 
    {
        return (
            <LoadingPage />
        );
    }

    if (!userId) {
        return <ErrorPage message= {ErrorTypes.NotLoggedIn} />
    }

    return (
        <div className="profile-page">
            <SummaryBox userData={data?.findUser!} owner={true} />
            <button className="profile-page logout" onClick={(e) => logoutAndRedirect(e)}>Logout</button>
            <MembershipSummary userData={data?.findUser!} owner={true} />
            <QuestionSummary userData={data?.findUser!} owner={true} />
            <ForecastSummary userData={data?.findUser!} owner={true} />
        </div>);
}

export default Profile;
