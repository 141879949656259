import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";

import { IFindQuestionResult } from '../../schemas/QuestionSchemas';
import Forecast from "../Forecast/Forecast";
import Question from "./Question";
import { FIND_QUESTION } from "../../queries/Questions";
import LoadingPage from '../Common/Loading';
import { getUsername } from '../../auth';
import { ErrorPage, ErrorTypes } from '../Common/Error';

interface QuestionParams {
    id: string
}

function Questions() {
    let { id } = useParams<QuestionParams>();
    const { data, loading } = useQuery<IFindQuestionResult>(FIND_QUESTION, {
        variables: { id }
    });

    if (loading) {
        return (
            <LoadingPage />
        )
    }
    
    if (!loading && !data?.findQuestion) {
        return (
            <ErrorPage message={ErrorTypes.UnAuthorized} />
        );
    }

    const node = data!.findQuestion;

    return (
        <div className="Questions full-question">
            <Question minimal={false} detailed={true} question={node} showQuestionLink={false} showResolvePrompt={getUsername() === node.creator.username} />
            {
                node.forecasts.edges.length > 0
                ?   
                    <div>
                        <div className="forecast-section-divider" >
                        Forecasts
                        </div>
                        {node.forecasts.edges.map(
                            (forecast) =>
                                <Forecast key={forecast.node.id} forecast={forecast.node} possibilities={node.possibilities}/>
                            )}
                    </div>
                :   <div className="forecast-section-divider"> 
                        No Forecasts yet
                    </div>
            }
        </div>
    );
}

export default Questions;
