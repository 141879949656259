import { IQuestion } from '../../schemas/QuestionSchemas';

interface IOpenQuestionOptionsProps {
  question: IQuestion;
}

const OpenQuestionOptions = (props: IOpenQuestionOptionsProps) => {  
  const data = props.question;

  return (
    <div className="question-possibilities">
        <ul>
            {data.possibilities.map((possibility: string, index: number) => 
            <li className="question-possibility" key={index}>
                {possibility}
            </li>
            )}
        </ul>
    </div>
  );
};

export default OpenQuestionOptions;
