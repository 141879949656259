import { gql } from "@apollo/client";

export const ALL_TOPICS =
gql`
    query {
        allTopics
        {
            edges
            {
                node
                {
                    id
                    name
                }
            }
        }
    }`;
