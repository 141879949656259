import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";

import { login } from '../../auth';
import '../../styles/Login.css';
import { ErrorPage, ErrorTypes } from '../Common/Error';
import LoadingPage from '../Common/Loading';

interface VerifyUserParams {
    code: string
}

function VerifyPasswordReset() {
    let { code } = useParams<VerifyUserParams>();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [ error, setError ] = useState(ErrorTypes.None);

    useEffect(() => {
        fetch(`/auth/click/passwordreset`, {
            method: 'post',
            body: JSON.stringify({ 'code': code })
        }).then(r => r.json())
            .then(response => {
                setLoading(false);
                if (response.error === "code_not_valid") {
                    setError(ErrorTypes.CodeNotValid);
                } else if(response.error === "user_is_not_verified") {
                    setError(ErrorTypes.VerificationRequired);
                } else if (response.username && response.user_id && response.access_token) {
                    login(response);
                    history.push('/remembermyself');
                }
            });
    });

    if (loading) {
        return (
            <div>
                <LoadingPage />
            </div >
        );
    }

    return (
        <ErrorPage message={error} />
    )
}

export default VerifyPasswordReset;
