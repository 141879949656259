export enum SuccessTypes {
    Verified,
    Registered,
    PasswordResetInitiated,
    PasswordSuccessfullyReset,
    None
}

interface ISuccessProps {
    message: SuccessTypes
}

function GetSuccessMessage(type: SuccessTypes) {
    switch (type) {
        case SuccessTypes.PasswordResetInitiated:
            return "Click the link in your email to complete your password reset!"
        case SuccessTypes.Registered:
            return "Click the verification link we've sent to your email address to complete your registration!";
        case SuccessTypes.Verified:
            return "You're verified! :) Please login with your username and password.";
        case SuccessTypes.PasswordSuccessfullyReset:
            return "Password reset! Welcome back to the pod."
        default:
            return "Something went wrong :(";
    }
}

export function SuccessPage(props: ISuccessProps) {
    if (props.message === SuccessTypes.None) {
        return null;
    }

    return (
        <div className="success-message">
            {GetSuccessMessage(props.message)}
        </div>
    );
}
