import { gql } from "@apollo/client";

export const ADD_GROUP = 
    gql`mutation 
        addGroup(
            $name: String!,
            $description: String!,
            $isPrivate: Boolean!,
            $members: [String!]!) { 
                addGroup(
                    name: $name,
                    description: $description,
                    isPrivate: $isPrivate,
                    members: $members) {
                        group {
                            id
                            name
                            description
                            isPrivate
                            owner {
                                username
                            }
                            users {
                                edges {
                                    node {
                                        user {
                                            username
                                        }
                                        associationType
                                    }
                                }
                            }
                        }
                    }
            }`;

export const REMOVE_USER_FROM_GROUP =
    gql`mutation
        removeUserFromGroup(
            $groupId: String!,
            $targetUserId: String) {
                removeUserFromGroup(
                    groupId: $groupId,
                    targetUserId: $targetUserId) {
                        group {
                            id
                            name
                            description
                            isPrivate
                            owner {
                                username
                            }
                            users {
                                edges {
                                    node {
                                        user {
                                            username
                                        }
                                        associationType
                                    }
                                }
                            }
                        }
                    }
            }`;