export enum ErrorTypes {
    CodeNotValid,
    NotLoggedIn,
    NoForecasts,
    NoQuestions,
    WrongLogin,
    NoBrierScore,
    VerificationRequired,
    UserEmailAlreadyRegistered,
    UserNameAlreadyRegistered,
    UserDoesNotExist,
    GroupAlreadyExists,
    PasswordMismatch,
    Unknown,
    UnAuthorized,
    None
}

interface IErrorProps {
    message: ErrorTypes
}

function GetErrorMessage(type: ErrorTypes) {
    switch (type) {
        case ErrorTypes.CodeNotValid:
            return "Sorry, this code is not valid!"
        case ErrorTypes.NotLoggedIn:
            return "Please log in to access this page"
        case ErrorTypes.NoQuestions:
            return "No relevant questions to show"
        case ErrorTypes.NoForecasts:
            return "No relevant forecasts to show"
        case ErrorTypes.NoBrierScore:
            return "Start making forecasts to gain a Brier Score."
        case ErrorTypes.WrongLogin:
            return "Wrong username/password combination"
        case ErrorTypes.VerificationRequired:
            return "Verification incomplete! Complete your registration by clicking the link sent to your email. Then try login."
        case ErrorTypes.UserEmailAlreadyRegistered:
            return "That email address is already registered! Use a different one or login."
        case ErrorTypes.UserNameAlreadyRegistered:
            return "That user name is already registered! Use a different one or login."
        case ErrorTypes.Unknown:
            return "Something went wrong :(. Please try again later!"
        case ErrorTypes.UserDoesNotExist:
            return "User does not exist! :o"
        case ErrorTypes.PasswordMismatch:
            return "Passwords don't match"
        case ErrorTypes.GroupAlreadyExists:
            return "A group with that name already exists."
        case ErrorTypes.UnAuthorized:
            return "You are not authorized to view this page."
        default:
            return "Something went wrong :("
    }
}

export function ErrorPage(props: IErrorProps) {
    if (props.message === ErrorTypes.None) {
        return null;
    }

    return (
        <div className="error-message">
            {GetErrorMessage(props.message)}
        </div>
    );
}

export class GraphQLErrorCodes {
    public static UserWithEmailDoesNotExist = "EMAIL_NOT_EXISTS";

    public static UserWithEmailAlreadyExists = "EMAIL_EXISTS";

    public static UserWithUsernameAlreadyExists = "USERNAME_EXISTS";

    public static UserNotVerified = "USER_NOT_VERIFIED";

    public static GroupAlreadyExists = "GROUP_EXISTS";
}