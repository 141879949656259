import {
    Chart as ChartJS,
    CategoryScale,
    Tooltip,
    LinearScale,
    BarElement,
    Title,
    Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, Tooltip, LinearScale, BarElement, Title, Legend);

interface Dataset {
    label: string;
    data: number[];
    backgroundColor: string;
}

interface IForecastChartProps {
    labels: string[];
    data: (number[])[];
    dataCategories: string[];
}

// Monochromatic blue palette
const colorOptions = [
    '#4169e1',
    '#87cefa',
    '#000080',
    '#191970',
    '#0f52ba',
    '#6495ed',
    '#4682b4',
    '#add8e6',
    '#b0e0e6',
    '#add8e6',
]

function generateDataFromProps(props: IForecastChartProps): Dataset[] {
    return props.labels.map((_, index) => {

        const values = props.data.map((data) => data[index]);

        return {
            label: props.labels[index],
            data: values,
            backgroundColor: colorOptions[index % colorOptions.length],
        }
    })
}

const ForecastChart = (props: IForecastChartProps) => {

    let width = window.screen.width;
    const chartData = {
        labels: props.dataCategories,
        datasets: generateDataFromProps(props),
    };

    const options = {
        indexAxis: 'y' as const,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
                stacked: true
            },
            x: {
                stacked: true
            }
        },
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: false,
            },
        }
    };

    return (
        <div className='chart-summary-responses'>
            <Bar height={width > 768 ? 30 : 80} data={chartData} options={options} />
        </div>
    );
};

export default ForecastChart;