import { gql } from "@apollo/client";

export const ADD_FORECAST = gql`mutation addForecast(
    $questionId: String!,
    $explanation: String!,
    $responses: [Float!]!) 
    { 
        addForecast(
            explanation: $explanation,
            questionId: $questionId,
            responses: $responses) {
                forecast {
                    id
                    question {
                        id
                        creatorId
                        forecasts {
                            edges {
                                node {
                                    id
                                    responses
                                    forecasterId
                                    explanation
                                    forecaster {
                                        username
                                    }
                                }
                            }
                        }
                    }
                }
            }
    }`;