import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { login } from '../../auth';
import { ErrorTypes, ErrorPage } from '../Common/Error';
import { SuccessTypes, SuccessPage } from '../Common/Success';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function LoginPage() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [ error, setError ] = useState(ErrorTypes.None);
  const history = useHistory();
  const handleFieldChange = (newValue: string, changeHandler: React.Dispatch<React.SetStateAction<string>>) => { changeHandler(newValue.trim()); }

  let successMessage = useQuery().get("registered") === 'true' ? SuccessTypes.Registered : SuccessTypes.None;
  let verificationError = ErrorTypes.None;

  if (useQuery().get("verified") === 'true') {
    successMessage = SuccessTypes.Verified;
  }

  const [ success, setSuccess ] = useState(successMessage);

  const onSubmitClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let opts = {
      'username': username,
      'password': password
    }

    fetch('/auth/login', {
      method: 'post',
      body: JSON.stringify(opts)
    }).then(r => r.json())
      .then(response => {
        if (response.access_token) {
          login(response);
          history.push('/');
        }
        else if (response.error === "verification required") {
          setError(ErrorTypes.VerificationRequired);
          setSuccess(SuccessTypes.None);
        }
        else {
          setError(ErrorTypes.WrongLogin);
          setSuccess(SuccessTypes.None);
        }
      });
  }

  return (
    <div>
      <ErrorPage message={verificationError} />
      <ErrorPage message={error} />
      <SuccessPage message={success} />
      <form className="loginForm"
            onSubmit={onSubmitClick}>
        <label className="loginForm">Username</label>
        <input
          className="loginForm"
          placeholder="superforecaster"
          type="text"
          required={true}
          value={username}
          onChange={e => handleFieldChange(e.target.value, setUsername)}
        />
        <label className="loginForm">Password</label>
        <input
          className="loginForm"
          placeholder="cuddlybunnies123"
          type="password"
          required={true}
          value={password}
          onChange={e => handleFieldChange(e.target.value, setPassword)}
        />
        <button className="loginForm" type="submit">Login</button>
        <a className="loginForm" href="/forgotmyself">Forgot password?</a>
      </form>
    </div >
  );
}

export default LoginPage;
