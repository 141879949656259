import { useQuery } from '@apollo/client';

import Question from './Question';
import { IAllQuestionResult } from '../../schemas/QuestionSchemas';
import { ALL_QUESTIONS_BY_USER_PRIVILEGE } from '../../queries/Questions';
import LoadingPage from '../Common/Loading';

interface IQuestionsProps {
  minimal: boolean;
}

function Questions(props: IQuestionsProps) {
  const { data, loading } = useQuery<IAllQuestionResult>(ALL_QUESTIONS_BY_USER_PRIVILEGE);

  const questionData = data?.allQuestionsByUserPrivilege;

  if (loading) {
    return (
      <LoadingPage />
    )
  }

  return (
    <div className="Questions">
      {questionData?.map(
        (question) =>
            <Question
              minimal={props.minimal}
              detailed={false}
              key={question.id}
              question={question}
              showQuestionLink={true}
              showResolvePrompt={false} />
      )}
    </div>);
}

export default Questions;
