import { useState } from 'react';

import { IUserResult } from '../../schemas/UserSchema';
import Question from '../Question/Question';
import Forecast from '../Forecast/Forecast';
import { ErrorTypes, ErrorPage } from '../Common/Error';
import { IGroupResult } from '../../schemas/GroupSchema';

interface ProfileSummaryProps {
    userData: IUserResult,
    owner: boolean
}

interface GroupSummaryProps {
    userData: IGroupResult
    owner: boolean;
}

export function SummaryBox(props: ProfileSummaryProps) {
    return (
        <div className="profile-summary">
            <div className="profile-summary-header">
                {props.userData.username}'s Summary
            </div>
            <div className="profile-summary-element">
                Brier Score
            </div>
            <div className="profile-summary-element">
                {props.userData.score?.toFixed(2) ?? "-"}
            </div>
            <div className="profile-summary-element">
                Number of Questions
            </div>
            <div className="profile-summary-element">
                {props.userData.questions.edges.length}
            </div>
            <div className="profile-summary-element">
                Number of Forecasts
            </div>
            <div className="profile-summary-element">
                {props.userData.forecasts.edges.length}
            </div>
        </div>
    );
}

export function QuestionSummary(props: (ProfileSummaryProps | GroupSummaryProps)) {
    const [showQuestions, setShowQuestions] = useState(true);

    return (
        <div>
            {
                props?.userData.questions.edges.length! > 0
                    ? <div className="Questions no-padding auto-width">
                        {showQuestions
                            ? <button className="profile-section-title" onClick={(_) => setShowQuestions(!showQuestions)}>Questions ▼</button>
                            : <button className="profile-section-title" onClick={(_) => setShowQuestions(!showQuestions)}>Questions ▲</button>
                        }
                        {showQuestions && props?.userData.questions.edges.map(
                            (question) =>
                                <Question minimal={true} detailed={false} key={question.node.id} question={question.node} showQuestionLink={true} showResolvePrompt={props.owner} />
                        )}
                    </div>
                    : <ErrorPage message={ErrorTypes.NoQuestions} />
            }
        </div>
    );
}

export function ForecastSummary(props: ProfileSummaryProps) {
    const [showForecasts, setShowForecasts] = useState(true);
    return (
        <div>
            {
                props?.userData.forecasts.edges.length! > 0
                    ? <div className="Questions no-padding auto-width profile-section-summary">
                        {showForecasts
                            ? <button className="profile-section-title" onClick={(_) => setShowForecasts(!showForecasts)}>Forecasts ▼</button>
                            : <button className="profile-section-title" onClick={(_) => setShowForecasts(!showForecasts)}>Forecasts ▲</button>
                        }
                        {showForecasts && props?.userData.forecasts.edges.map(
                            (forecast) =>
                                <Forecast key={forecast.node.id} forecast={forecast.node} possibilities={forecast.node.question.possibilities} />
                        )}
                    </div>
                    : <ErrorPage message={ErrorTypes.NoForecasts} />
            }
        </div>
    );
}

export function MembershipSummary(props: ProfileSummaryProps) {
    const [showMemberships, setShowMemberships] = useState(true);
    let groupsData = props?.userData.groups.edges;

    if (groupsData.length === 0) {
        // There's no membership data to show.
        return null;
    }


    if (!props.owner) {
        // Filter out any groups that are set to private.
        groupsData = groupsData.filter((group) => !group.node.group.isPrivate);
    }

    return (
        <div>
            {
                <div className="Questions no-padding auto-width profile-section-summary">
                        {showMemberships
                            ? <button className="profile-section-title" onClick={(_) => setShowMemberships(!showMemberships)}>Memberships ▼</button>
                            : <button className="profile-section-title" onClick={(_) => setShowMemberships(!showMemberships)}>Memberships ▲</button>
                        }
                        {showMemberships && groupsData.map(
                            (group) =>
                                <span className='profile-section-group-summary-element' key={group.node.group.id}>
                                    <a className='about-page group-about' href={`/pod/${group.node.group.id}`}>{group.node.group.name}</a>
                                </span>
                        )}
                </div>
            }
        </div>
    );
}