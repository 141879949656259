import { gql } from "@apollo/client";

export const ADD_USER = 
gql`mutation addUser($username: String!, $password: String!, $email: String!) { 
    addUser(
        username: $username,
        password: $password,
        email: $email) {
            user {
                username
                id
            }
        }
    }`;

export const INITIATE_PASSWORD_RESET = 
gql`mutation initiatePasswordReset($email: String!) { 
    initiatePasswordReset(
        email: $email) {
            user {
                username
                id
            }
        }
    }`;

export const COMPLETE_PASSWORD_RESET = 
gql`mutation completePasswordReset($password: String!) { 
    completePasswordReset(
        password: $password) {
            user {
                username
                id
            }
        }
    }`;