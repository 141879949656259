import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { ErrorTypes, ErrorPage } from '../Common/Error';
import { SuccessTypes, SuccessPage } from '../Common/Success';
import { COMPLETE_PASSWORD_RESET } from '../../mutations/User';
import { IUserResult } from '../../schemas/UserSchema';

function ResetPassword() {
  const [password0, setPassword0] = useState<string>("");
  const [password1, setPassword1] = useState<string>("");

  const history = useHistory();

  const [ clientError, setError ] = useState(ErrorTypes.None);
  const handleFieldChange = (newValue: string, changeHandler: React.Dispatch<React.SetStateAction<string>>) => { changeHandler(newValue.trim()); }
  const [ completePasswordReset ] = useMutation<IUserResult, any>(COMPLETE_PASSWORD_RESET, {
        onCompleted(_) {
            setSuccess(SuccessTypes.PasswordSuccessfullyReset);
            // history.push('/');
        },
        onError: (err) => {
            console.log('error payload',err);
        }
    });

  const [ success, setSuccess ] = useState(SuccessTypes.None);

  const onSubmitClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password0 !== password1) {
        setError(ErrorTypes.PasswordMismatch);
    }
    else 
    {
        completePasswordReset({
            variables: 
            {
                password: password0
            }
        });
    }
  }

  if (success === SuccessTypes.PasswordSuccessfullyReset) {
    return (
        <div>
          <SuccessPage message={success} />
          <div className="success-message">
            <a className="success-message" href="/">- Back to forecasting -</a>
          </div>
        </div >
      );
  }

  return (
    <div>
      <ErrorPage message={clientError} />
      <form className="loginForm"
            onSubmit={onSubmitClick}>
        <label className="loginForm">New Password</label>
        <input
          className="loginForm"
          type="password"
          required={true}
          value={password0}
          onChange={e => handleFieldChange(e.target.value, setPassword0)}
        />
        <label className="loginForm">One more time</label>
        <input
          className="loginForm"
          type="password"
          required={true}
          value={password1}
          onChange={e => handleFieldChange(e.target.value, setPassword1)}
        />
        <button className="loginForm" type="submit">Reset Password</button>
      </form>
    </div >
  );
}

export default ResetPassword;
