import { gql } from "@apollo/client";

export const GET_GROUP = gql`
    query Group($id: ID!) {
        group(id: $id) {
            id
            name
            description
            isPrivate
            owner {
                username
            }
            users {
                edges {
                    node {
                        user {
                            username
                        }
                        associationType
                    }
                }
            }
            questions {
                edges {
                    node {
                        id
                        title
                        description
                        creator {
                            username
                        }
                        possibilities
                        openDate
                        closeDate
                        resolutionDate
                        outcome
                        topics {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        group {
                            id
                            name
                        }
                    }
                }
            }
        }
    }`

export const ALL_GROUPS = gql`
    query {
        allGroups{
            edges {
                node {
                    id
                    name
                    description
                    isPrivate
                    users {
                        edges {
                            node {
                                user {
                                    id
                                    username
                                }
                            }
                        }
                    }
                    questions {
                        edges {
                            node {
                                title
                                creator {
                                    username
                                }
                            }
                        }
                    }
                }
            }
        }
    }`;