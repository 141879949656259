import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select';
import { IAllUsersResult } from '../../schemas/UserSchema';
import { ALL_USERS } from '../../queries/User';
import LoadingPage from '../Common/Loading';
import { getUsername } from '../../auth';

interface IUserPickerProps {
    userChangeHandler: (value: ValueType<OptionTypeBase, boolean>, actionMeta: ActionMeta<OptionTypeBase>) => void;
    numSelected: number;
}

function UserPicker(props: IUserPickerProps) {

    // Query the backend for all Users.
    const { loading, error, data } = useQuery<IAllUsersResult>(ALL_USERS);
    const maxUsers = 20;
    let userData;

    if (data) {
        let ownUsername = getUsername();
        userData = data.allUsers.edges
                    // Filter out the current user.
                    .filter((user) => user.node.username !== ownUsername)
                    .map((user) => ({ 
                        label: user.node.username,
                        value: user.node.id
                    }));
    }

    if (loading) {
        return(
            <LoadingPage />
        );
    }

    if (error) {
        console.log(error);
    }

    return (
        <Select
            closeMenuOnSelect={false}
            isMulti={true}
            options={props.numSelected === maxUsers ? [] : userData}
            onChange={props.userChangeHandler}
            noOptionsMessage={() => {
              return props.numSelected === maxUsers ? `Maximum ${maxUsers} topics allowed` : 'No options available' ;
            }}
        />
    );
}

export default UserPicker;
