import { gql } from "@apollo/client";

export const FIND_USER =
  gql`
    query findUser($id: Int!) 
    {
        findUser(id: $id) {
            username
            score
        }
    }`;

export const ALL_USERS = 
    gql`
        query allUsers {
            allUsers {
                edges {
                    node {
                        id
                        username
                    }
                }
            }
        }`;

export const GET_USER_QUESTIONS =
    gql`
        query findUser($id: Int!) 
        {
            findUser(id: $id) {
                questions {
                    edges {
                        node {
                            id
                            title
                            description
                            creator {
                              username
                            }
                            possibilities
                            openDate
                            closeDate
                            resolutionDate
                            outcome
                            topics {
                                edges {
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
        }
    }`;

export const GET_USER_FORECASTS =
    gql`
        query findUser($id: Int!) 
        {
            findUser(id: $id) {
                forecasts {
                    edges {
                        node {
                            id
                            forecasterId
                            questionId
                            responses
                            explanation
                            creationDatetime
                            forecaster {
                                username
                            }
                            question {
                                id
                                title
                            }
                        }
                    }
                }
        }
    }`;


export const GET_USER_GROUPS =
    gql`
        query findUser($id: Int!) 
        {
            findUser(id: $id) {
                groups {
                    edges {
                        node {
                            group {
                                id
                                name
                                description
                            }
                            associationType
                        }
                    }
                    }
            }
        }`;

export const GET_USER_PROFILE_DATA = 
gql`
    query findUser($id: Int!) 
    {
        findUser(id: $id) {
            username
            score
            forecasts 
            {
                edges 
                {
                    node {
                        id
                        forecasterId
                        questionId
                        responses
                        explanation
                        creationDatetime
                        forecaster {
                            username
                        }
                        question {
                            id
                            title
                            possibilities
                        }
                        score
                    }
                }
            }
            questions {
                edges {
                    node {
                        id
                        title
                        description
                        creator {
                          username
                        }
                        possibilities
                        openDate
                        closeDate
                        resolutionDate
                        outcome
                        topics {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        group {
                            id
                            name
                        }
                    }
                }
            }
            groups {
                edges {
                    node {
                        group {
                            id
                            name
                            description
                        }
                        associationType
                    }
                }
            }
        }
    }`;

export const GET_USER_PROFILE_DATA_BY_USERNAME = 
    gql`
        query findUserByUsername($username: String!) 
        {
            findUserByUsername(username: $username) {
                username
                score
                forecasts 
                {
                    edges
                    {
                        node {
                            id
                            forecasterId
                            questionId
                            responses
                            explanation
                            creationDatetime
                            forecaster {
                                username
                            }
                            question {
                                id
                                title
                                possibilities
                            }
                            score
                        }
                    }
                }
                questions {
                    edges {
                        node {
                            id
                            title
                            description
                            creator {
                              username
                            }
                            possibilities
                            openDate
                            closeDate
                            resolutionDate
                            outcome
                            topics {
                                edges {
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                            group {
                                id
                                name
                            }
                        }
                    }
                }
                groups {
                    edges {
                        node {
                            group {
                                id
                                name
                                description
                                isPrivate
                            }
                            associationType
                        }
                    }
                }
            }
        }`;
