import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";

import { IFindQuestionResult } from '../../schemas/QuestionSchemas';
import { IAddForecastResult } from '../../schemas/ForecastSchemas';
import { FIND_QUESTION } from '../../queries/Questions';
import { ADD_FORECAST } from '../../mutations/Forecast';
import { useState } from 'react';
import { DisplayText, InputText } from '../Common/Text';

interface QuestionParams {
    id: string
}

function MakeForecast() {
    let { id } = useParams<QuestionParams>();
    const { data, loading } = useQuery<IFindQuestionResult>(FIND_QUESTION, {
        variables: { id }
    });
    const history = useHistory();
    const [addForecast] = useMutation<IAddForecastResult, any>(ADD_FORECAST, {
        onCompleted({ addForecast }) {
            // Navigate to the question which has just been forecasted.
            history.push(`/question/${id}`);
        }
    });
    const node = data?.findQuestion!;
    const [responses, setResponses] = useState([...new Array<number>(node?.possibilities.length ?? 0)].fill(0.0));
    const [totalError, setTotalError] = useState("");
    const [explanation, setExplanation] = useState("");

    if (loading) {
        return (
            <div className="Loading">
                Loading! Bear with us.
            </div>
        )
    }

    if (!data?.findQuestion) {
        return (
            <div className="Error">
                No data returned :(.
            </div>
        );
    }

    const getSumRespones = () => {
        let tot: number = 0;
        for (var response of responses) {
            if (response) {
                tot += response;
            }
        }
        return tot;
    }

    const handleResponseChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        event.preventDefault();
        responses[index] = +event.target.value;
        setResponses([...responses]);
        const good = "happy";
        const bad = "sad";
        const footerId = "createForecast-footer";

        let totalPercent = getSumRespones();
        if (totalPercent === 100) {
            document.getElementById(footerId)!.classList.add(good);
            document.getElementById(footerId)!.classList.remove(bad);
        }
        else if(totalPercent > 100) {
            document.getElementById(footerId)!.classList.add(bad);
            document.getElementById(footerId)!.classList.remove(good);
        } else {
            document.getElementById(footerId)!.classList.remove(good);
            document.getElementById(footerId)!.classList.remove(bad);
        }
    }

    const handleForecastSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let total = getSumRespones();

        if (total !== 100) {
            setTotalError(`The probabilities you assign should sum to 100%. They currently add up to ${total}%.`);
            return;
        }

        addForecast({
            variables: {
                questionId: id,
                explanation: explanation,
                responses: responses
            }
        });
    }

    let userProfileLink = `/user/${node.creator.username}`;

    return (
        <div className="Forecasts">
            <div className="Question-tile" key={node.id}>
                <div className="question-title">{node.title}</div>
                <div className="question-subtitle">
                By <b><a href={userProfileLink} className="creator-link">{node.creator.username}</a></b> ⦙ {new Date(node.closeDate).toDateString()}
                </div>
                <div className="question-description">
                    <DisplayText className='question-description' content={node.description} />
                </div>
                <form className="createForecast" onSubmit={e => handleForecastSubmit(e)}>
                    <div className="createForecast question-possibilities">
                        <div className="createForecast question-possibility-header">
                            <div className="createForecast input-header">
                                Option
                            </div>
                            <div className="createForecast input-header">
                                Probability (%)
                            </div>
                        </div>
                        {node.possibilities.map((possibility: string, index: number) =>
                            <div className="createForecast question-possibility" key={index}>
                                <div className="createForecast question-possibility-text">
                                    <p className="question-possibility">{possibility}</p>
                                </div>
                                <input
                                    className="createForecast question-possibility-response"
                                    required={true}
                                    key={index}
                                    type="number"
                                    defaultValue={0}
                                    min={0}
                                    max={100}
                                    step={0.1}
                                    onChange={e => handleResponseChange(e, index)} >
                                </input>
                            </div>)}
                        <div id="createForecast-footer" className="createForecast question-possibility-footer">
                            <div className="createForecast input-header">
                                Total
                            </div>
                            <div className="createForecast input-header">
                                {getSumRespones()} / 100
                            </div>
                        </div>
                    </div>
                    <label className="createForecast explanation input-header">Explanation - <a href="https://www.markdownguide.org/cheat-sheet/">Markdown is supported</a></label>
                    <InputText handleChange={setExplanation} content={explanation} className="createForecast explanation" />
                    <div className="gentle-error-message">
                        {totalError}
                    </div>
                    <button className="createForecast">Forecast</button>
                </form>
            </div>
        </div>
    );
}

export default MakeForecast;
