import { IQuestion } from '../../schemas/QuestionSchemas';

interface IResolvedQuestionOptions {
  question: IQuestion;
}

const ResolvedQuestionOptions = (props: IResolvedQuestionOptions) => {  
  const data = props.question;

  return (
    <div className="question-possibilities">
        <ul>
            {data.possibilities.map((possibility: string, index: number) => 
              <li className={index === data.outcome ? "question-possibility winner" : "question-possibility"} key={index}>
                  {possibility}
              </li>
            )}
        </ul>
    </div>
  );
};

export default ResolvedQuestionOptions;
