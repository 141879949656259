import React from 'react';
import Questions from './Question/Questions';
import '../styles/App.css';

function Homepage() {

  const [minimal, setMinimal] = React.useState(false);

  return (
    <div className="App">
      <div className="homepage content">
        <div className='minimal'>
          <input
            className="minimal"
            name='minimal'
            onChange={() => setMinimal(!minimal)}
            type="checkbox"
            id="minimal" />
          <label className="minimal">minimal</label>
        </div>
        <Questions minimal={minimal} />
      </div>
    </div >
  );
}

export default Homepage;
