import React from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import './styles/index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

const httpLink = createHttpLink({
  uri: "/api",
});

const authLink = setContext((_, { headers }) => {
  const tokenJson = localStorage.getItem("REACT_TOKEN_AUTH_KEY");
  const token = tokenJson != null
    ? `Bearer ${JSON.parse(tokenJson).access_token}`
    : "";
  return {
    headers: {
      ...headers,
      Authorization: token,
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allQuestionsByUserPrivilege : {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            }
          }
        }
    }
  }})
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
