import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import '../../styles/Login.css';
import { ErrorPage, ErrorTypes } from '../Common/Error';
import LoadingPage from '../Common/Loading';

interface VerifyUserParams {
    code: string
}

function VerifyUser() {
    let { code } = useParams<VerifyUserParams>();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`/auth/verify`, {
            method: 'post',
            body: JSON.stringify({ 'code': code })
        }).then(r => r.json())
            .then(response => {
                if (response.username && response.user_id) {
                    history.push('/login?verified=true');
                } else {
                    setLoading(false);
                }
            });
    });

    if (loading) {
        return (
            <div>
                <LoadingPage />
            </div >
        );
    }

    return (
        <ErrorPage message={ErrorTypes.CodeNotValid} />
    )
}

export default VerifyUser;
