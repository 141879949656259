export default function LandingPage() {
    return (
        <div>
            <div className="landing-page container">
                <section className="landing-page title">
                        <p className="landing-page section-title">
                            Welcome to Rorqual!
                        </p>
                </section>
                <section className="landing-page description">
                        <p className="landing-page section-description">
                            Develop your forecasting ability using an iterative, data-driven approach.
                        </p>
                </section>
                <section className="landing-page description">
                        <p className="landing-page section-description">
                            Work with friends and strangers to make more accurate predictions, while improving your <a className="landing-page description" href="https://en.wikipedia.org/wiki/Brier_score">Brier Score</a>.
                        </p>
                </section>
                <section className="landing-page call-to-action">
                        <a className="landing-page call-to-action" href="/signup">Sign up to get started</a>
                </section>
            </div>
            <svg id="visual" viewBox="0 0 900 300" width="100%" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"><path d="M0 66L129 72L257 134L386 110L514 58L643 114L771 122L900 49L900 301L771 301L643 301L514 301L386 301L257 301L129 301L0 301Z" fill="#3aaceb"></path><path d="M0 86L129 82L257 106L386 129L514 149L643 115L771 119L900 124L900 301L771 301L643 301L514 301L386 301L257 301L129 301L0 301Z" fill="#2b93c8"></path><path d="M0 170L129 169L257 160L386 103L514 124L643 123L771 157L900 121L900 301L771 301L643 301L514 301L386 301L257 301L129 301L0 301Z" fill="#1d7aa7"></path><path d="M0 177L129 178L257 141L386 163L514 166L643 132L771 130L900 188L900 301L771 301L643 301L514 301L386 301L257 301L129 301L0 301Z" fill="#106386"></path><path d="M0 150L129 171L257 161L386 200L514 207L643 161L771 204L900 208L900 301L771 301L643 301L514 301L386 301L257 301L129 301L0 301Z" fill="#0e5e80"></path><path d="M0 213L129 216L257 206L386 205L514 182L643 221L771 213L900 215L900 301L771 301L643 301L514 301L386 301L257 301L129 301L0 301Z" fill="#106386"></path><path d="M0 219L129 202L257 232L386 205L514 211L643 232L771 211L900 237L900 301L771 301L643 301L514 301L386 301L257 301L129 301L0 301Z" fill="#1d7aa7"></path><path d="M0 230L129 235L257 237L386 250L514 259L643 249L771 244L900 250L900 301L771 301L643 301L514 301L386 301L257 301L129 301L0 301Z" fill="#2b93c8"></path><path d="M0 268L129 282L257 276L386 263L514 272L643 277L771 277L900 278L900 301L771 301L643 301L514 301L386 301L257 301L129 301L0 301Z" fill="#3aaceb"></path>
            </svg>
        </div>
    )
}
