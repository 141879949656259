export function getDate(date: Date) {
    var dd: number = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear().toString();
    
    var ret = yyyy + "-";

    if (mm < 10) {
        ret = ret + "0"
    }
    ret = ret + mm + "-";

    if (dd < 10) {
        ret = ret + "0"
    }
    ret = ret + dd;

    return ret;
}

export function getDaysFromToday(offset: number) {
    const newDate = new Date();
    newDate.setDate((new Date()).getDate() + 1);
    return getDate(newDate);
}