export default function AboutPage() {
    return (
        <div className="about-page container">
            <section className="about-page">
                <p className="about-page section-title">Mission</p>
                <p className="about-page section-description">
                    Inspired by Philip E. Tetlock's book <a className="about-page" href="https://en.wikipedia.org/wiki/Superforecasting:_The_Art_and_Science_of_Prediction">Superforecasting: The Art and Science of Prediction</a>, we wanted to build an engaging platform where people could build models on how to make better predictions.
                </p>
                <p className="about-page section-description">
                    The competitive spirit in many of us likes to make predictions on how events will turn out. Based on the information we have, we make micro-predictions constantly. Which electric car stocks will go up? Which road will have less traffic at 6 PM? How long will it take us to vaccinate the global population? The better we get at making accurate estimates for tomorrow, the better decisions we can make today.
                </p>
                <p className="about-page section-description">
                    Whether forecasting on a global scale or a personal scale, we wanted to make a social platform that we could use to competitively improve society's forecasting ability. We wanted it to be fun, iterative, and engaging.
                </p>
            </section>
            <section className="about-page">
                <p className="about-page section-title">Rorqual allows you to</p>
                <ul className="about-page">
                    <li>Pose questions and crowdsource forecasts for events that you care about</li>
                    <li>Track and iteratively improve your ability to forecast using a data-driven approach</li>
                    <li>Allow users to track their <a className="about-page" href="https://en.wikipedia.org/wiki/Brier_score">Brier Score</a> for all forecasts across time</li>
                    <li>Make future predictions informed by those of yourself, your tribes, or the general public</li>
                    <li>Improve your forecasting capabilities and make better decisions as a society</li>
                </ul>
            </section>
            <section className="about-page">
                <p className="about-page section-title">How it works</p>
                <ul className="about-page">
                    <li><a className="about-page" href="/signup">Sign up here!</a> You'll need an account to create questions and make forecasts, as well as to maintain and track a <a className="about-page" href="https://en.wikipedia.org/wiki/Brier_score">Brier Score</a></li>
                    <li>Start forecasting and creating questions to solicit predictions from others</li>
                    <li>How <b>Questions</b> work:
                        <ul>
                            <li>
                                What makes Questions a little bit different from a regular poll is that there are multiple outcome possibilities, and the Forecaster is giving probability estimates to each of the outcomes. Though the Forecaster can give 100% to a single option on a question, they also have the opportunity to create a more nuanced response.
                            </li>
                            <li>
                                Along with the basics, like a title, description, and option set, every question is required to have a closing date. The closing date allows the creator to specify the final date for accepting new forecasts.
                            </li>
                            <li>
                                Once a Question is closed, now the creator has to resolve it to the correct answer. This may depend on external events that don't have a set determination date, so there's not a set window in which a closed question must be resolved.
                            </li>
                        </ul>
                    </li>
                    <li>
                        How <b>Forecasts</b> work:
                        <ul>
                            <li>
                                Forecasts are made on existing Questions. For all the options the Creator of a Question has provided, the Forecaster must make probability estimates on each of the outcome options, ranging from 0 to 100. The sum of all the estimates must come out to 100.
                            </li>
                            <li>
                                Once the Creator resolves a Question, all the Forecasts made on that Question are automatically scored and the Forecasters' Brier Scores are updated.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Keep in mind:
                        <ul>
                            Once a Question or Forecast is made, it cannot be deleted or modified. Think of it as creating a public ledger which holds you accountable for all of the predictions you make.
                        </ul>
                    </li>
                </ul>
            </section>
            <section className="about-page">
                <p className="about-page section-title">Ground rules</p>
                <ul className="about-page">
                    <li>Be respectful. We reserve the right to take down any questions we deem inappropriate for the platform.</li>
                    <li>Be collaborative and have fun! Share explanations of your forecasts to help others learn and grow from your expertise. Be open-minded, and learn from others' experiences when your forecasts fall short.</li>
                </ul>
            </section>
            <section className="about-page">
                <p className="about-page section-title">Made by</p>
                <ul className="about-page">
                    <li><a className="about-page" href="https://github.com/debanjum">debanjum</a> and <a className="about-page" href="https://github.com/sabaimran/">saba</a></li>
                </ul>
            </section>
        </div>
    )
}
