import { useState } from "react";
import { Link } from "react-router-dom";
import { getUsername } from '../../auth';

interface INavigationProps {
    loggedIn: boolean;
}

export function NavigationMenu(props: INavigationProps) {

    return (
        <div className="header-navigation">
            <nav className="header-navigation">
                <ul className="nav-menu" id="nav-menu">
                    {props.loggedIn &&
                    <li className="nav-menu header-navigation">
                        <Link className="header-navigation" to="/create/question">Create Question</Link>
                    </li>}
                    {props.loggedIn &&
                    <li className="nav-menu header-navigation" >
                        <Link className="header-navigation" to="/create/pod">Create Pod</Link>
                    </li>}
                    <li className="nav-menu header-navigation">
                        {!props.loggedIn
                        ? <Link className="header-navigation" to="/signup">Sign Up</Link>
                        : <Link className="header-navigation" to="/me">{getUsername()}</Link>}
                    </li>
                    {!props.loggedIn && 
                        <li className="nav-menu header-navigation">
                            <Link className="header-navigation" to="/login">Login</Link>
                        </li>
                    }
                </ul>
            </nav>
        </div>
    );
}

export function ExpandMenuButton() {
    const [ symbol, setSymbol ] = useState("⊶");
    const toggleMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        let currentState = document.getElementById("nav-menu")!.style.display;

        if (!currentState || currentState === "none") {
            setSymbol("⊷");
        } else {
            setSymbol("⊶");
        }

        document.getElementById("nav-menu")!.style.display = (!currentState || currentState === "none") ? "block" : "none";
    }
    return (<button onClick={e => {toggleMenu(e)}} className="expand-menu" >{symbol}</button>);
}