import { gql } from '@apollo/client';

export const ADD_QUESTION = gql`mutation addQuestion(
    $title: String!,
    $description: String!,
    $closeDate: Date!,
    $possibilities: [String!]!,
    $groupId: String!,
    $topics: [String!]!) 
    { 
        addQuestion(
            title: $title,
            description: $description,
            closeDate: $closeDate,
            possibilities: $possibilities,
            groupId: $groupId,
            topics: $topics) {
                question {
                    id
                    creator {
                        username
                    }
                    possibilities
                    openDate
                    closeDate
                    title
                    description,
                    topics {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                }
            }
    }`;

export const RESOLVE_QUESTION = gql`mutation resolveQuestion(
    $questionId: String!,
    $index: Int!) 
    { 
        resolveQuestion(
            questionId: $questionId,
            index: $index) {
                question {
                    id
                    possibilities
                    outcome
                    resolutionDate
                    creatorId
                }
            }
    }`;