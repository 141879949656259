import { IForecast } from "../../schemas/ForecastSchemas";
import { Link } from 'react-router-dom';
import { DisplayText } from "../Common/Text";

interface IForecastProps {
  forecast: IForecast;
  possibilities: string[];
}

const Forecast = (props: IForecastProps) => {
    const data = props.forecast;
    const possibilities = props.possibilities;

    let userProfileLink = `/user/${data.forecaster.username}`;

    let creationDate = new Date(data.creationDatetime);
    return (
      <div className="Question-tile">
        <div className="question-subtitle">
          {data.question?.id &&
            <Link className="forecast-go-to-question" to={`/question/${data.question?.id}`}>{data.question?.title}</Link>
          }
          <p className="question-subtitle"><b><a href={userProfileLink} className="creator-link">{data.forecaster.username}</a></b> ⦙ {creationDate.toDateString()}</p>
        </div>
        {
          data.score !== null && 
          <p className="question-subtitle">Brier Score: {data.score.toFixed(2)}</p>
        }
        <div className="question-description">
          <DisplayText className="question-description" content={data.explanation} />
        </div>
        <div className="question-possibilities">
          {
            [...Array(possibilities.length)].map((item, index) => 
            <div className="question-possibilities-grid" key={index+"forecast-response"}>
              <div className="question-possibility-forecast" key={index+"possibility"}>
                {possibilities[index]}
              </div>
              <div className="question-possibility-forecast" key={index+"response"}>
                {data.responses[index]}%
              </div>
            </div>
            )
          }
        </div>
      </div>
    );
  };
  
  export default Forecast;
  