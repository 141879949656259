import { useMutation } from '@apollo/client';

import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { OptionTypeBase, ValueType } from "react-select";
import { ADD_GROUP } from '../../mutations/Group';
import { IAddGroupResult } from '../../schemas/GroupSchema';
import { ErrorPage, ErrorTypes, GraphQLErrorCodes } from '../Common/Error';
import UserPicker from "../User/UserPicker";

export function CreateGroup() {
    const formClassName = "createGroupForm";

    const history = useHistory();

    const [ clientError, setError ] = useState(ErrorTypes.None);
    const [ name, setName ] = useState<string>("");
    const [ description, setDescription ] = useState<string>("");
    const [ isPrivate, setIsPrivate ] = useState<boolean>(false);
    const [ users, setUsers ] = useState<ValueType<OptionTypeBase, boolean> | undefined>(undefined);

    const [ addGroup ] = useMutation<IAddGroupResult, any>(ADD_GROUP,   {
        onCompleted({ addGroup }) {
            // Navigate to the newly created group.
            history.push(`/pod/${addGroup.group.id}`);
        },
        onError(err) {
            if (err.graphQLErrors[0].extensions!["code"] === GraphQLErrorCodes.GroupAlreadyExists) {
                setError(ErrorTypes.GroupAlreadyExists);
            }
        }
    });

    const handleStringChange = (newValue: string, lambda: React.Dispatch<React.SetStateAction<string>>) => {
        lambda(newValue.trimStart());
    }

    const handleCheckboxChange = (oldValue: boolean, lambda: React.Dispatch<React.SetStateAction<boolean>>) => {
        lambda(!oldValue);
    }

    const handleUserChange = (value: ValueType<OptionTypeBase, boolean>, actionMeta: any) => {
        setUsers(value);
    }

    const handleSubmit = () => {
        // Create the new group using the ADD_GROUP mutation.
        addGroup({
            variables: {
                name: name,
                description: description,
                isPrivate: isPrivate,
                members: users?.map((user: { value: any; }) => user.value) || []
            }
        });
    }

    return(
        <div className={formClassName+"-wrapper"}>
            <h1 className="form-title">Create a New Forecasting Pod</h1>
            <ErrorPage message={clientError} />
            <form className={formClassName}
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                <div className={formClassName}>
                    <p className={formClassName}>Pods are groups of forecasters. You will automatically become the owner and one of the admins for any new pods you create.</p>
                </div>
                <div className={formClassName}>
                    <label className={formClassName}>Name</label>
                </div>
                <div className={formClassName}>
                    <p className={formClassName}>Give your pod a name. Pod names do have to be unique, but we'll let you know at the end if yours is already taken.</p>
                </div>
                <div className={formClassName}>
                    <input
                        className={formClassName}
                        required = {true}
                        value = {name}
                        onChange = {e => handleStringChange(e.target.value, setName)}
                        type = "text" />
                </div>
                <div className={formClassName}>
                    <label className={formClassName}>Description</label>
                </div>
                <div className={formClassName}>
                    <p className={formClassName}>Let the members know a little bit about your pod. What's brings you all together? What do you plan to Forecast on?</p>
                </div>
                <div className={formClassName}>
                    <input
                        className={formClassName}
                        required = {true}
                        value = {description}
                        onChange = {e => handleStringChange(e.target.value, setDescription)}
                        type = "text" />
                </div>
                <div className={formClassName}>
                    <label className={formClassName}>Members</label>
                </div>
                <div className={formClassName}>
                    <p className={formClassName}>Select other users whom you'd like to invite to your pod.</p>
                </div>
                {/* Multi-Option Select of all Users across Rorqual */}
                <div className={formClassName}>
                    <UserPicker userChangeHandler={handleUserChange} numSelected={users?.length} />
                </div>
                <div className={formClassName}>
                    <label className={formClassName}>Private</label>
                </div>
                <div className={formClassName}>
                    <p className={formClassName}>Your pod can be private or public. Anyone can see questions and forecasts in a public pod, whereas the content in private pods is limited to the members. Forecasts in private pods do not affect your Brier Score. Once selected, you cannot change the privacy setting on your pod.</p>
                </div>
                <div className={formClassName}>
                    {/* <div className={formClassName + "checkbox-wrapper"} > */}
                        <input
                            className={formClassName}
                            onChange={e => handleCheckboxChange(isPrivate, setIsPrivate)}
                            type = "checkbox" />
                    {/* </div> */}
                </div>
                <div className={formClassName}>
                    <button className={formClassName} type="submit">Create Pod</button>
                </div>
            </form>
        </div>
    )
}