import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select';
import { getUserId } from '../../auth';
import { GET_USER_GROUPS } from '../../queries/User';
import { IFindUserResult } from '../../schemas/UserSchema';

interface IGroupPickerProps {
    groupChangeHandler: (value: ValueType<OptionTypeBase, boolean>, actionMeta: ActionMeta<OptionTypeBase>) => void;
    numSelected: number;
}

function GroupPicker(props: IGroupPickerProps) {

    let userId = getUserId();

    const { data } = useQuery<IFindUserResult>(GET_USER_GROUPS, {
        variables: { id: userId },
        skip: !userId
    });
    const maxGroups = 1;

    let groupsData = data?.findUser.groups.edges.map(
        (group) => ({
            label: group.node.group.name,
            value: group.node.group.id
        })
    )

    return (
        <Select
            closeMenuOnSelect={false}
            required={true}
            isMulti={false}
            options={props.numSelected === maxGroups ? [] : groupsData}
            onChange={props.groupChangeHandler}
            noOptionsMessage={() => {
              return props.numSelected === maxGroups ? `Maximum ${maxGroups} groups allowed` : 'No options available' ;
            }}
        />
    );
}

export default GroupPicker;
