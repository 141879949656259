import ReactMarkdown from 'react-markdown'
import { useState } from 'react';

import '../../styles/Common.css';

interface TextProps {
    content: string;
    className: string;
}

interface InputTextProps extends TextProps {
    handleChange: React.Dispatch<React.SetStateAction<string>>;
}

// A regular expression to handle https URLs
const URL_REGEX = /https?:\/\/[^\s]+/g;

// A function which takes a string and, if it finds any words which match the URL regex, wraps them in brackets, like markdown style.
function convertURLsToMarkdownFormat(text: string) {
    const words = text.split(/(\s+)/);
    return words.map((word) => {
        const match = word.match(URL_REGEX);
        return match && (match[0].length === word.length) ? (
            `[${word}](${word})`) : word}).join(' ');
}

export function DisplayText(props: TextProps) {
    // Split the input content into words based on spaces or new lines.
    // This will allow us to iterate over each word and check if it's a URL.
	return (
        <p className={"markdown-renderer" + props.className}>
            <ReactMarkdown>
                {convertURLsToMarkdownFormat(props.content)}
            </ReactMarkdown>
        </p>
	);
}

export function InputText(props: InputTextProps) {
    const handleStringChange = (newValue: string, lambda: React.Dispatch<React.SetStateAction<string>>) => {
        lambda(newValue.trimStart());
    }

    const [showPreview, setShowPreview] = useState(false);

    return(
        <div className={props.className}>
            <input 
                onChange={() => setShowPreview(!showPreview)}
                name="show-preview-toggle"
                id="show-preview-toggle"
                type="checkbox"
                className="toggle"/>
            <label
                className="toggle-label">
                    Show Preview
            </label>
            <div>
                <textarea
                    className={props.className + " markdown-text-input"}
                    value = {props.content}
                    onChange = {e => handleStringChange(e.target.value, props.handleChange)}
                />
            </div>
            {
                showPreview &&
                <div className='preview'>
                    <div>
                        <label className='description-preview'>Preview</label>
                    </div>
                    <div>
                        <DisplayText className={props.className} content={props.content} />
                    </div>
                </div>
            }
        </div>
    )
}