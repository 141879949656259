import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { IFindUserByUsernameResult } from '../../schemas/UserSchema';
import { GET_USER_PROFILE_DATA_BY_USERNAME } from '../../queries/User';
import LoadingPage from '../Common/Loading';
import { ErrorTypes, ErrorPage } from '../Common/Error';
import { SummaryBox, QuestionSummary, ForecastSummary, MembershipSummary } from './ProfileComponents';

interface ProfileParams {
    username: string
}

function PublicProfile() {
    let { username } = useParams<ProfileParams>();

    const { data, loading, error } = useQuery<IFindUserByUsernameResult>(GET_USER_PROFILE_DATA_BY_USERNAME, {
        variables: { username: username },
        skip: !username
    });
    
    if (loading) 
    {
        return (
            <LoadingPage />
        );
    }

    if (!data?.findUserByUsername ?? true) {
        return <ErrorPage message={ErrorTypes.UserDoesNotExist} />
    }

    if (error) {
        return <ErrorPage message={ErrorTypes.Unknown} />
    }

    return (
        <div className="profile-page">
            <SummaryBox userData={data.findUserByUsername!} owner={false} />
            <MembershipSummary userData={data.findUserByUsername!} owner={false} />
            <QuestionSummary userData={data.findUserByUsername!} owner={false} />
            <ForecastSummary userData={data.findUserByUsername!} owner={false} />
        </div>);
}

export default PublicProfile;
