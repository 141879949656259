import { createAuthProvider } from 'react-token-auth';

const token_auth_key = "REACT_TOKEN_AUTH_KEY";

export const getUsername = () => {
	return JSON.parse(localStorage.getItem(token_auth_key) || "{}").username ?? "";
}

export const getUserId = () => {
	return JSON.parse(localStorage.getItem(token_auth_key) || "{}").user_id ?? "";
}

export const [useAuth, authFetch, login, logout] =
	createAuthProvider({
		accessTokenKey: "access_token",
		onUpdateToken: (token: any) => fetch('/auth/refresh', {
			method: 'POST',
			body: token.access_token
		}).then(r => r.json())
});

export const refreshIfRequired = () => {
	const jwtToken = JSON.parse(localStorage.getItem(token_auth_key) || "{}");
	const expirationDate = getExpirationDate(jwtToken.access_token);

	if (!expirationDate) {
		return;
	}

	if (expirationDate < Date.now()) {
		fetch('/auth/refresh', {
			method: 'POST',
			body: JSON.stringify(jwtToken)
		}).then(r => r.json())
		.then(token => {
		  if (token.access_token) {
			login(token);
		  } else {
			logout();
		  }
		});
	}

	// User's token is still valid.
}

const getExpirationDate = (jwtToken: string): number | null => {
	if (!jwtToken) {
		return null;
	}

	const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

	// multiply by 1000 to convert seconds into milliseconds
	return (jwt && jwt.exp && jwt.exp * 1000) || null;
};