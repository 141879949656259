import { IQuestionsEdges } from "./QuestionSchemas";
import { IUserResult } from "./UserSchema";

export interface IGroupResult {
    id: string;
    owner: IUserResult;
    name: string;
    description: string;
    isPrivate: boolean;
    users: IUsersEdges;
    questions: IQuestionsEdges;
}

export interface IAddGroupResult {
    addGroup: IGroupNode;
}

export interface IRemoveUserFromGroupResult {
    removeUserFromGroup: IGroupNode;
}

export interface IGroupNode {
    group: IGroupResult;
    associationType: number;
}

export interface IAllGroupsResult {
    allGroups: IGroupEdges;
}

export interface IGroupEdges {
    edges: IGroupEdge[];
}

export interface IGroupEdge {
    node: IGroupResult;
}

export interface IUsersEdges {
    edges: IUserEdge[];
}

export interface IUserEdge {
    node: IUserNode;
}

export interface IUserNode {
    user: IUserResult;
    associationType: number;
}

export enum GroupAssociationType {
    ADMIN = 1,
    MEMBER = 2,
    BANNED = 3
}

