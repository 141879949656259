import '../styles/App.css';
import { useAuth, refreshIfRequired } from '../auth';

import logo from '../logo.svg';
import Homepage from './Homepage';
import QuestionPage from './Question/QuestionPage';
import CreateQuestion from './Question/CreateQuestion';
import SignUpPage from './User/SignUpPage';
import LoginPage from './User/LoginPage';
import ForgotPassword from './User/ForgotPassword';
import MakeForecast from './Forecast/MakeForecast';
import Profile from './User/Profile';
import { NavigationMenu, ExpandMenuButton } from './Common/Nav';
import Footer from './Common/Footer';
import AboutPage from './Common/About';
import LandingPage from './LandingPage';
import VerifyUser from './User/VerifyUser';
import VerifyPasswordReset from './User/VerifyPasswordReset';
import PublicProfile from './User/PublicProfile';
import ResetPassword from './User/ResetPassword';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { CreateGroup } from './Groups/CreateGroup';
import { GroupPage } from './Groups/GroupPage';

function App() {
  
  refreshIfRequired();

  const [logged] = useAuth();
  return (
    <div className="App">
      <Router>
        <header className="header">
          <Link className='header-logo' to="/"> <img src={logo} className="header-logo" alt="logo" /> </Link>
          <div className="header-title">
            <Link className="header-title" to="/">Rorqual</Link>
          </div>
          <ExpandMenuButton />
          <NavigationMenu loggedIn={logged} />
        </header>
        <Switch>
          <Route path="/question/:id">
            <QuestionPage />
          </Route>
          <Route path="/pod/:id">
            <GroupPage />
          </Route>
          <Route path="/create/question">
            {logged ? <CreateQuestion /> : <Redirect to='/login' />}
          </Route>
          <Route path="/create/pod">
            {logged ? <CreateGroup /> : <Redirect to='/login' />}
          </Route>
          <Route path="/forecast/:id">
            <MakeForecast />
          </Route>
          <Route path="/signup">
            <SignUpPage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/forgotmyself">
            <ForgotPassword />
          </Route>
          <Route path="/verify/:code">
            <VerifyUser />
          </Route>
          <Route path="/swimbackin/:code">
            <VerifyPasswordReset />
          </Route>
          <Route path="/remembermyself">
            <ResetPassword />
          </Route>
          <Route path="/user/:username">
            <PublicProfile />
          </Route>
          <Route path="/me">
            <Profile />
          </Route>
          <Route path="/landing">
            <LandingPage />
          </Route>
          <Route path="/feed">
            <Homepage />
          </Route>
          <Route path="/">
            {
              logged ? <Homepage /> : <LandingPage />
            }
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
