import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { IQuestion, IResolveQuestionResult } from '../../schemas/QuestionSchemas';
import { RESOLVE_QUESTION } from '../../mutations/Question';

interface IUnresolvedQuestionOptionsProps {
  question: IQuestion;
}

const UnresolvedQuestionOptions = (props: IUnresolvedQuestionOptionsProps) => {  
  const data = props.question;

  const [ outcome, setOutcome ] = useState(0);
  const [ resolveQuestion ] = useMutation<IResolveQuestionResult, any>(RESOLVE_QUESTION);

  const submitResolution = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resolveQuestion({ variables: {
      questionId: data.id,
      index: outcome }
    });
  }

  return (
    <div>
      <hr/>
        <form 
          className="question-tile outcome-selection" 
          onSubmit={e => {
              submitResolution(e);
          }}>
          <p className="question-tile outcome-selection">Mark the winning outcome.</p>
          <div className="question-possibilities">
              {data.possibilities.map((possibility: string, index: number) => 
              <div className="question-tile outcome-option" key={index}>
                  <input 
                    type="radio"
                    value={index}
                    checked={outcome === index}
                    onChange={(_) => setOutcome(index)}
                  />
                  <label>{possibility}</label>
              </div>
              )}
          </div>
          <button className="question-tile resolve-outcome-option">Resolve</button>
      </form>
    </div>
  );
};

export default UnresolvedQuestionOptions;
